import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../components/Navbar'
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import AuthContext from '../context/AuthContext'
import { USER_URL } from '../services/Constants';
import PlayerProfile from '../components/PlayerProfile';
import CoachProfile from '../components/CoachProfile';
import AgentProfile from '../components/AgentProfile';

export const ViewUserProfile = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const userId = params.get('user_id');

    // State variable of search user profile in Navbar

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [isPlayerSummary, setIsPlayerSummary] = useState(false);
    const [isPlayerTnternationalStats, setIsPlayerTnternationalStats] = useState(false);
    const [isPlayerDomesticStats, setIsPlayerDomesticStats] = useState(false);
    const [isCoachSummary, setIsCoachSummary] = useState(false);
    const [isCoachTnternationalStats, setIsCoachTnternationalStats] = useState(false);
    const [isCoachDomesticStats, setIsCoachDomesticStats] = useState(false);
    const [isAgentSummary, setIsAgentSummary] = useState(false);

    //  State variable to manage user profile info

    let { user, authTokens } = useContext(AuthContext)

    // const [userData, setUserData] = useState('')

    const [userPersonalData, setUserPersonalData] = useState('')
    const [dobMonth, setDobMonth] = useState('')
    const [dobDay, setDobDay] = useState('')
    const [dobYear, setDobYear] = useState('')
    const [currentAge, setCurrentAge] = useState('')
    const [heightInFeetInches, setHeightInFeetInches] = useState('')
    const [permanentAddress, setPermanentAddress] = useState('')
    const [profileBio, setProfileBio] = useState('')
    const [profileDesc, setProfileDesc] = useState([])
    const [videoInfo, setVideoInfo] = useState([])
    const [sportProfile, setSportProfile] = useState([])
    const [clubHistory, setClubHistory] = useState([])
    const [playerData, setPlayerData] = useState([])
    const [coachData, setCoachData] = useState([])
    const [coachHistory, setCoachHistory] = useState([])
    const [agentData, setAgentData] = useState([])
    const [agentHistory, setAgentHistory] = useState([])

    const [isPlayerVideoAvailable, setIsPlayerVideoAvailable] = useState('No video found')
    const [isCoachVideoAvailable, setIsCoachVideoAvailable] = useState('No video found')
    const [isAgentVideoAvailable, setIsAgentVideoAvailable] = useState('No video found')

    // function responsible for handling navigation with params

    const navigateToSearchDataWithParams = (item) => {
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const navigateToSearchAllWithParams = (item) => {
        console.log('navigateToSearchAllWithParams');
        console.log('Search Data:', item);
        navigate("/searchall", { state: { item } });
    };

    // get user info

    const userInfo = async () => {
        // e.preventDefault();
        console.log('user Personal Information');
        let response = fetch(`${USER_URL}/${userId}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        const data = (await response).json()
        console.log('data:', data)
        console.log('response:', response)
        const status = (await response).status
        if (status === 200) {
            const personalInfo = (await data)
            console.log('personalInfo: ', personalInfo)
            setUserPersonalData(personalInfo)
            // Profile Description
            // const desc = (await personalInfo.profile_description)
            // const description = desc.filter(item => item.profile_type === 'player');
            // if (description && description.length > 0) {
            //     const words = description[0].description.split(" ");
            //     const profileInfo = words.slice(0, 50).join(" ");
            //     setProfileBio(profileInfo)
            //     setProfileDesc(description)
            // }
            const desc = (await personalInfo.bio)
            const words = desc.split(" ");
            const profileInfo = words.slice(0, 50).join(" ");
            setProfileBio(profileInfo)
            setProfileDesc(desc)
            // // Year to age and format conversion
            if (personalInfo.dob !== null) {
                const dob = JSON.stringify(personalInfo.dob)
                const splitDob = dob.split("-")
                // console.log('year: ', splitDob[0])
                // console.log('month: ', splitDob[1])
                // console.log('day: ', splitDob[2])
                const yr = splitDob[0].slice(1);
                // console.log('year: ', yr)
                const dy = splitDob[2].slice(0, -1);
                // console.log('day: ', dy)
                if (splitDob[1] === '01') {
                    setDobMonth('Jan')
                } else if (splitDob[1] === '02') {
                    setDobMonth('Feb')
                } else if (splitDob[1] === '03') {
                    setDobMonth('Mar')
                } else if (splitDob[1] === '04') {
                    setDobMonth('April')
                } else if (splitDob[1] === '05') {
                    setDobMonth('May')
                } else if (splitDob[1] === '06') {
                    setDobMonth('June')
                } else if (splitDob[1] === '07') {
                    setDobMonth('July')
                } else if (splitDob[1] === '08') {
                    setDobMonth('Aug')
                } else if (splitDob[1] === '09') {
                    setDobMonth('Sep')
                } else if (splitDob[1] === '10') {
                    setDobMonth('Oct')
                } else if (splitDob[1] === '11') {
                    setDobMonth('Nov')
                } else if (splitDob[1] === '12') {
                    setDobMonth('Dec')
                }
                setDobDay(dy)
                setDobYear(yr)
                const currentYear = new Date().getFullYear();
                // console.log('current year:', currentYear);
                const age = currentYear - yr
                setCurrentAge(age)
            }
            // Height Conversion from centimeter to feet and inches
            const heightInCm = personalInfo.height;
            const heightInFeet = heightInCm / 30.48;
            const inches = (heightInFeet - Math.floor(heightInFeet)) * 12;
            // console.log(Math.floor(heightInFeet) + " feet " + Math.round(inches) + " inches");
            setHeightInFeetInches(Math.floor(heightInFeet) + " ft " + Math.round(inches) + " in")
            // Address
            const pmAddress = (await personalInfo.permanent_address[0])
            if (pmAddress) {
                setPermanentAddress(pmAddress)
            }
            // Video Clip
            const video = (await personalInfo.video_clip)
            setVideoInfo(video)
            // Player
            const player = (await personalInfo.player[0])
            console.log('player', player);
            if (player) {
                setPlayerData(player)
                const club = (await player.club)
                // console.log('player club', club);
                if (club) {
                    setClubHistory(club)
                    club.forEach(item => {
                        if (item.summary !== null && item.summary !== '')
                            setIsPlayerSummary(true);
                        if (item.league_type === 'International')
                            setIsPlayerTnternationalStats(true);
                        if (item.league_type === 'Domestic')
                            setIsPlayerDomesticStats(true);
                    })
                }
            }
            // Coach
            const coach = (await personalInfo.coach[0])
            console.log('coach', coach);
            if (coach) {
                setCoachData(coach)
                const career = (await coach.carreer_history)
                // console.log('coach career history', career);
                if (career) {
                    setCoachHistory(career)
                    career.forEach(item => {
                        if (item.summary !== null && item.summary !== '')
                            setIsCoachSummary(true);
                        if (item.league_type === 'International')
                            setIsCoachTnternationalStats(true);
                        if (item.league_type === 'Domestic')
                            setIsCoachDomesticStats(true);
                    })
                }
            }
            // Agent
            const agent = (await personalInfo.agent[0])
            console.log('agent', agent);
            if (agent) {
                setAgentData(agent)
                const career = (await agent.career_history)
                if (career) {
                    setAgentHistory(career)
                    career.forEach(item => {
                        if (item.summary !== null)
                            setIsAgentSummary(true);
                    })
                }
            }
            // Current profile type of a User
            const profileType = (await personalInfo.sport_profile_type)
            console.log('profileType: ', profileType)
            setSportProfile(profileType)
        }
    }

    useEffect(() => {
        userInfo()
        // playerInfo()
        // console.log('User Data: ', userData)
    }, [user, authTokens])

    const clubHistoryList = clubHistory.map((club) => {
        if (club.endorsement_request.length > 0) {
            if ((club.league_type === 'Domestic') && (club.endorsement_request[0].status === 'approved')) {
                return (
                    <tr key={club.id}>
                        <th scope="row">{club.club_name}</th>
                        <td>{club.from_year} - {club.to_year}</td>
                        <td>{club.league_name}</td>
                        <td>{club.games_played}</td>
                        <td>{club.club_goals}</td>
                        <td>{club.club_assists}</td>
                        <td>{club.club_passes}</td>
                        <td>{club.club_saved_goals}</td>
                        <td>{club.takles_per_game}</td>
                        <td>{club.dribles_completed_per_game}</td>
                        <td>{club.interceptions_per_game}</td>
                        <td>{club.key_passes_per_game}</td>
                        <td>{club.shots_per_game}</td>
                        <td>{club.clean_sheets_per_game}</td>
                        <td>{club.club_yellow_card}</td>
                        <td>{club.club_red_card}</td>
                        <td className='text-center'>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                color='#0f0'
                            />
                        </td>
                    </tr>
                );
            } else if ((club.league_type === 'Domestic') && (club.endorsement_request[0].status === 'rejected')) {
                return (
                    <tr key={club.id}>
                        <th scope="row">{club.club_name}</th>
                        <td>{club.from_year} - {club.to_year}</td>
                        <td>{club.league_name}</td>
                        <td>{club.games_played}</td>
                        <td>{club.club_goals}</td>
                        <td>{club.club_assists}</td>
                        <td>{club.club_passes}</td>
                        <td>{club.club_saved_goals}</td>
                        <td>{club.takles_per_game}</td>
                        <td>{club.dribles_completed_per_game}</td>
                        <td>{club.interceptions_per_game}</td>
                        <td>{club.key_passes_per_game}</td>
                        <td>{club.shots_per_game}</td>
                        <td>{club.clean_sheets_per_game}</td>
                        <td>{club.club_yellow_card}</td>
                        <td>{club.club_red_card}</td>
                        <td className='text-center'>
                            <FontAwesomeIcon
                                icon={faXmarkCircle}
                                color='#f00'
                            />
                        </td>
                    </tr>
                );
            } else if ((club.league_type === 'Domestic') && (club.endorsement_request[0].status === 'pending')) {
                return (
                    <tr key={club.id}>
                        <td>{club.club_name}</td>
                        <td>{club.from_year} - {club.to_year}</td>
                        <td>{club.league_name}</td>
                        <td>{club.games_played}</td>
                        <td>{club.club_goals}</td>
                        <td>{club.club_assists}</td>
                        <td>{club.club_passes}</td>
                        <td>{club.club_saved_goals}</td>
                        <td>{club.takles_per_game}</td>
                        <td>{club.dribles_completed_per_game}</td>
                        <td>{club.interceptions_per_game}</td>
                        <td>{club.key_passes_per_game}</td>
                        <td>{club.shots_per_game}</td>
                        <td>{club.clean_sheets_per_game}</td>
                        <td>{club.club_yellow_card}</td>
                        <td>{club.club_red_card}</td>
                        <td className='text-center'>
                            {'Not verified'}
                        </td>
                    </tr>
                );
            }
        } else {
            if (club.league_type === 'Domestic') {
                return (
                    <tr key={club.id}>
                        <td>{club.club_name}</td>
                        <td>{club.from_year} - {club.to_year}</td>
                        <td>{club.league_name}</td>
                        <td>{club.games_played}</td>
                        <td>{club.club_goals}</td>
                        <td>{club.club_assists}</td>
                        <td>{club.club_passes}</td>
                        <td>{club.club_saved_goals}</td>
                        <td>{club.takles_per_game}</td>
                        <td>{club.dribles_completed_per_game}</td>
                        <td>{club.interceptions_per_game}</td>
                        <td>{club.key_passes_per_game}</td>
                        <td>{club.shots_per_game}</td>
                        <td>{club.clean_sheets_per_game}</td>
                        <td>{club.club_yellow_card}</td>
                        <td>{club.club_red_card}</td>
                        <td className='text-center'>
                            {'Not verified'}
                        </td>
                    </tr>
                );
            }
        }
    })

    const internationalHistoryList = clubHistory.map((club) => {
        // console.log('club: ', club);
        if (club.endorsement_request.length > 0) {
            if ((club.league_type === 'International') && (club.endorsement_request[0].status === 'approved')) {
                return (
                    <tr key={club.id}>
                        <td>{club.club_name}</td>
                        <td>{club.from_year} - {club.to_year}</td>
                        <td>{club.league_name}</td>
                        <td>{club.games_played}</td>
                        <td>{club.club_goals}</td>
                        <td>{club.club_assists}</td>
                        <td>{club.club_passes}</td>
                        <td>{club.club_saved_goals}</td>
                        <td>{club.takles_per_game}</td>
                        <td>{club.dribles_completed_per_game}</td>
                        <td>{club.interceptions_per_game}</td>
                        <td>{club.key_passes_per_game}</td>
                        <td>{club.shots_per_game}</td>
                        <td>{club.clean_sheets_per_game}</td>
                        <td>{club.club_yellow_card}</td>
                        <td>{club.club_red_card}</td>
                        <td className='text-center'>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                color='#0f0'
                            />
                        </td>
                    </tr>
                );
            } else if ((club.league_type === 'International') && (club.endorsement_request[0].status === 'rejected')) {
                return (
                    <tr key={club.id}>
                        <td>{club.club_name}</td>
                        <td>{club.from_year} - {club.to_year}</td>
                        <td>{club.league_name}</td>
                        <td>{club.games_played}</td>
                        <td>{club.club_goals}</td>
                        <td>{club.club_assists}</td>
                        <td>{club.club_passes}</td>
                        <td>{club.club_saved_goals}</td>
                        <td>{club.takles_per_game}</td>
                        <td>{club.dribles_completed_per_game}</td>
                        <td>{club.interceptions_per_game}</td>
                        <td>{club.key_passes_per_game}</td>
                        <td>{club.shots_per_game}</td>
                        <td>{club.clean_sheets_per_game}</td>
                        <td>{club.club_yellow_card}</td>
                        <td>{club.club_red_card}</td>
                        <td className='text-center'>
                            <FontAwesomeIcon
                                icon={faXmarkCircle}
                                color='#f00'
                            />
                        </td>
                    </tr>
                );
            } else if ((club.league_type === 'International') && (club.endorsement_request[0].status === 'pending')) {
                return (
                    <tr key={club.id}>
                        <td>{club.club_name}</td>
                        <td>{club.from_year} - {club.to_year}</td>
                        <td>{club.league_name}</td>
                        <td>{club.games_played}</td>
                        <td>{club.club_goals}</td>
                        <td>{club.club_assists}</td>
                        <td>{club.club_passes}</td>
                        <td>{club.club_saved_goals}</td>
                        <td>{club.takles_per_game}</td>
                        <td>{club.dribles_completed_per_game}</td>
                        <td>{club.interceptions_per_game}</td>
                        <td>{club.key_passes_per_game}</td>
                        <td>{club.shots_per_game}</td>
                        <td>{club.clean_sheets_per_game}</td>
                        <td>{club.club_yellow_card}</td>
                        <td>{club.club_red_card}</td>
                        <td className='text-center'>
                            {'Not verified'}
                        </td>
                    </tr>
                );
            }
        } else {
            if ((club.league_type === 'International')) {
                return (
                    <tr key={club.id}>
                        <td>{club.club_name}</td>
                        <td>{club.from_year} - {club.to_year}</td>
                        <td>{club.league_name}</td>
                        <td>{club.games_played}</td>
                        <td>{club.club_goals}</td>
                        <td>{club.club_assists}</td>
                        <td>{club.club_passes}</td>
                        <td>{club.club_saved_goals}</td>
                        <td>{club.takles_per_game}</td>
                        <td>{club.dribles_completed_per_game}</td>
                        <td>{club.interceptions_per_game}</td>
                        <td>{club.key_passes_per_game}</td>
                        <td>{club.shots_per_game}</td>
                        <td>{club.clean_sheets_per_game}</td>
                        <td>{club.club_yellow_card}</td>
                        <td>{club.club_red_card}</td>
                        <td className='text-center'>
                            {'Not verified'}
                        </td>
                    </tr>
                );
            }
        }
    })

    const playerSummaryList = clubHistory.map((club) => {
        if (club.summary !== '' && club.summary !== null) {
            return (
                <>
                    <h5><b>From {club.from_year} To {club.to_year}, {club.league_type}</b></h5>
                    <div>{club.summary}</div>
                </>
            );
        }
    })

    const careerHistoryListInternational = coachHistory.map((history) => {
        if (history.endorsement_request.length > 0) {
            if ((history.league_type === 'International') && (history.endorsement_request[0].status === 'apporved')) {
                return (
                    <tr key={history.id}>
                        <td>{history.club_name}</td>
                        <td>{history.from_year} - {history.to_year}</td>
                        <td>{history.league_name}</td>
                        <td className='text-center'>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                color='#0f0'
                            />
                        </td>
                    </tr>
                );
            } else if ((history.league_type === 'International') && (history.endorsement_request[0].status === 'rejected')) {
                return (
                    <tr key={history.id}>
                        <td>{history.club_name}</td>
                        <td>{history.from_year} - {history.to_year}</td>
                        <td>{history.league_name}</td>
                        <td className='text-center'>
                            <FontAwesomeIcon
                                icon={faXmarkCircle}
                                color='#f00'
                            />
                        </td>
                    </tr>
                );
            } else if ((history.league_type === 'International') && (history.endorsement_request[0].status === 'pending')) {
                return (
                    <tr key={history.id}>
                        <td>{history.club_name}</td>
                        <td>{history.from_year} - {history.to_year}</td>
                        <td>{history.league_name}</td>
                        <td className='text-center'>
                            {'Not verified'}
                        </td>
                    </tr>
                );
            }
        } else {
            if (history.league_type === 'International') {
                return (
                    <tr key={history.id}>
                        <td>{history.club_name}</td>
                        <td>{history.from_year} - {history.to_year}</td>
                        <td>{history.league_name}</td>
                        <td className='text-center'>
                            {'Not verified'}
                        </td>
                    </tr>
                );
            }
        }
    })

    const careerHistoryListRegional = coachHistory.map((history) => {
        if (history.endorsement_request.length > 0) {
            if ((history.league_type === 'Domestic') && (history.endorsement_request[0].status === 'approved')) {
                return (
                    <tr key={history.id}>
                        <td>{history.club_name}</td>
                        <td>{history.from_year} - {history.to_year}</td>
                        <td>{history.league_name}</td>
                        <td className='text-center'>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                color='#0f0'
                            />
                        </td>
                    </tr>
                );
            } else if ((history.league_type === 'Domestic') && (history.endorsement_request[0].status === 'rejected')) {
                return (
                    <tr key={history.id}>
                        <td>{history.club_name}</td>
                        <td>{history.from_year} - {history.to_year}</td>
                        <td>{history.league_name}</td>
                        <td className='text-center'>
                            <FontAwesomeIcon
                                icon={faXmarkCircle}
                                color='#f00'
                            />
                        </td>
                    </tr>
                );
            } else if ((history.league_type === 'Domestic') && (history.endorsement_request[0].status === 'pending')) {
                return (
                    <tr key={history.id}>
                        <td>{history.club_name}</td>
                        <td>{history.from_year} - {history.to_year}</td>
                        <td>{history.league_name}</td>
                        <td className='text-center'>{'Not Verified'}</td>
                    </tr>
                );
            }
        } else {
            if (history.league_type === 'Domestic') {
                return (
                    <tr key={history.id}>
                        <td>{history.club_name}</td>
                        <td>{history.from_year} - {history.to_year}</td>
                        <td>{history.league_name}</td>
                        <td className='text-center'>{'Not Verified'}</td>
                    </tr>
                );
            }
        }
    })

    const coachSummaryList = coachHistory.map((history) => {
        if (history.summary !== null && history.summary !== '') {
            return (
                <>
                    <h5><b>From {history.from_year} To {history.to_year}, {history.league_type}</b></h5>
                    <div>{history.summary}</div>
                </>
            );
        }
    })

    const agentCareerHistoryPlayersAndCoachesUnderMeEndorsementRequest = agentHistory.map((history) => {
        return (
            <div key={history.id}>
                <h5><b>From {history.from_year} To {history.to_year}, {history.company}, {history.state}, {history.country}</b></h5>
                {history.players_and_coaches_under_me.length > 0 &&
                    <div className='table-responsive'>
                        <table className="table table-sm table-light table-striped table-hover table-bordered mt-3">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Endorser</th>
                                    <th scope="col">Endorser Type</th>
                                    <th scope="col" className='text-center'>Verified</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                <>
                                    {history.players_and_coaches_under_me.map(playerAndCoaches => (
                                        // <div key={playerAndCoaches.id}>
                                        <>
                                            {playerAndCoaches.endorsement_request.length > 0 ?
                                                <>
                                                    {playerAndCoaches.endorsement_request[0].status === 'approved' ?
                                                        <tr key={playerAndCoaches.id}>
                                                            <td rowSpan={playerAndCoaches.endorsement_request.length}>{playerAndCoaches.name}</td>
                                                            <td rowSpan={playerAndCoaches.endorsement_request.length}>{playerAndCoaches.type}</td>
                                                            <td>{playerAndCoaches.endorsement_request[0].to_endorser.first_name} {playerAndCoaches.endorsement_request[0].to_endorser.last_name}</td>
                                                            <td>{playerAndCoaches.endorsement_request[0].type}</td>
                                                            <td className='text-center'>
                                                                <FontAwesomeIcon
                                                                    icon={faCheckCircle}
                                                                    color='#0f0'
                                                                />
                                                            </td>
                                                        </tr> :
                                                        <>
                                                            {playerAndCoaches.endorsement_request[0].status === 'rejected' ?
                                                                <tr key={playerAndCoaches.id}>
                                                                    <td rowSpan={playerAndCoaches.endorsement_request.length}>{playerAndCoaches.name}</td>
                                                                    <td rowSpan={playerAndCoaches.endorsement_request.length}>{playerAndCoaches.type}</td>
                                                                    <td>{playerAndCoaches.endorsement_request[0].to_endorser.first_name} {playerAndCoaches.endorsement_request[0].to_endorser.last_name}</td>
                                                                    <td>{playerAndCoaches.endorsement_request[0].type}</td>
                                                                    <td className='text-center'>
                                                                        <FontAwesomeIcon
                                                                            icon={faXmarkCircle}
                                                                            color='#f00'
                                                                        />
                                                                    </td>
                                                                </tr> :
                                                                <tr>
                                                                    <td rowSpan={playerAndCoaches.endorsement_request.length}>{playerAndCoaches.name}</td>
                                                                    <td rowSpan={playerAndCoaches.endorsement_request.length}>{playerAndCoaches.type}</td>
                                                                    <td>{playerAndCoaches.endorsement_request[0].to_endorser.first_name} {playerAndCoaches.endorsement_request[0].to_endorser.last_name}</td>
                                                                    <td>{playerAndCoaches.endorsement_request[0].type}</td>
                                                                    <td className='text-center'>Not Verified</td>
                                                                </tr>
                                                            }
                                                        </>
                                                    }
                                                    {playerAndCoaches.endorsement_request.slice(1).map(endorsement => (
                                                        endorsement.status === 'approved' ?
                                                            <tr key={endorsement.id}>
                                                                <td>{endorsement.to_endorser.first_name} {endorsement.to_endorser.last_name}</td>
                                                                <td>{endorsement.type}</td>
                                                                <td className='text-center'>
                                                                    <FontAwesomeIcon
                                                                        icon={faCheckCircle}
                                                                        color='#0f0'
                                                                    />
                                                                </td>
                                                            </tr>
                                                            :
                                                            <>
                                                                {endorsement.status === 'rejected' ?
                                                                    <tr key={endorsement.id}>
                                                                        <td>{endorsement.to_endorser.first_name} {endorsement.to_endorser.last_name}</td>
                                                                        <td>{endorsement.type}</td>
                                                                        <td className='text-center'>
                                                                            <FontAwesomeIcon
                                                                                icon={faXmarkCircle}
                                                                                color='#f00'
                                                                            />
                                                                        </td>
                                                                    </tr> :
                                                                    <tr key={endorsement.id}>
                                                                        <td>{endorsement.to_endorser.first_name} {endorsement.to_endorser.last_name}</td>
                                                                        <td>{endorsement.type}</td>
                                                                        <td className='text-center'>Not Verified</td>
                                                                    </tr>
                                                                }
                                                            </>
                                                    ))}
                                                </> :
                                                <tr>
                                                    <td>{playerAndCoaches.name}</td>
                                                    <td>{playerAndCoaches.type}</td>
                                                    <td colSpan={3} className='text-center'>No endorser found</td>
                                                </tr>
                                            }
                                        </>
                                        // </div>
                                    ))}
                                </>
                            </tbody>
                        </table>
                    </div>
                }
            </div >
        );
    })

    const agentCareerHistoryList = agentHistory.map((history) => {
        return (
            <tr key={history.id}>
                <td>{history.company}</td>
                <td>{history.from_year} - {history.to_year}</td>
                <td>{history.address_lane}</td>
                <td>{history.zip}</td>
                <td>{history.country}</td>
                <td>{history.email}</td>
                <td>{history.contact_no}</td>
            </tr>
        );
    })

    const agentSummaryList = agentHistory.map((history) => {
        if (history.summary !== null && history.summary !== '') {
            return (
                <>
                    <h5><b>From {history.from_year} To {history.to_year}, {history.league_type}</b></h5>
                    <div>{history.summary}</div>
                </>
            );
        }
    })

    const achievementList = clubHistory.map(club => {
        // if (club.achievements !== null || club.achievements !== '') {
        const parsedData = club.achievements !== null && club.achievements !== '' ? club.achievements.split('\r\n') : null;
        return (
            <>
                {
                    club.achievements !== null && club.achievements !== '' ?
                        <div key={club.id} className='d-flex flex-row justify-content-between'>
                            <div className='col-md-5'>
                                <p className='card-text'>{club.from_year} - {club.to_year}</p>
                            </div>
                            <div className='col-md-7'>
                                {/* <p className='card-text'>{club.achievements}</p> */}
                                {parsedData && parsedData.length > 0 &&
                                    <ul>
                                        {parsedData.map((item, index) => (
                                            <li key={index} className='card-text'>{item}</li>
                                        ))}
                                    </ul>
                                }
                            </div>
                        </div>
                        : null
                }
            </>
        )
        // }
    })

    const videoInfoList = videoInfo.map((videos) => {
        if (videos.profile_type === 'player') {
            setIsPlayerVideoAvailable('');
            return (
                <div key={videos.id} className='d-flex flex-row justify-content-between mb-1'>
                    <div className='col-md-6'>
                        <p className='card-text'>{videos.title}</p>
                    </div>
                    <div className='col-md-6'>
                        <p className='card-text'>{videos.clip_url}</p>
                    </div>
                </div>
            );
        }
    })

    const videoInfoListCoach = videoInfo.map((videos) => {
        if (videos.profile_type === 'coach') {
            isCoachVideoAvailable('');
            return (
                <div key={videos.id} className='d-flex flex-row justify-content-between mb-1'>
                    <div className='col-md-6'>
                        <p className='card-text'>{videos.title}</p>
                    </div>
                    <div className='col-md-6'>
                        <p className='card-text'>{videos.clip_url}</p>
                    </div>
                </div>
            );
        }
    })

    const videoInfoListAgent = videoInfo.map((videos) => {
        isAgentVideoAvailable('');
        if (videos.profile_type === 'agent') {
            return (
                <div key={videos.id} className='d-flex flex-row justify-content-between mb-1'>
                    <div className='col-md-6'>
                        <p className='card-text'>{videos.title}</p>
                    </div>
                    <div className='col-md-6'>
                        <p className='card-text'>{videos.clip_url}</p>
                    </div>
                </div>
            );
        }
    })

    return (
        <>
            <div className="modal fade modal-xl" id="profileModal" tabIndex="-1" aria-labelledby="profileModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="profileModalLabel">Profile Info</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='col'>
                                <p className='card-text'>{profileDesc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} />
            {
                loading &&
                <div className='overlay'>
                    <div className="container">
                        <div className='search-content' style={{ marginTop: 60, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
                            <ul className='content-list' style={{ padding: 10 }}>
                                {
                                    searchData.length > 0 ?
                                        searchData.slice(0, 3).map((item) => (
                                            <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                                                {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                                            </li>
                                        ))

                                        : <li className='list-element'>No data found</li>
                                }
                                {searchData.length > 0 ?
                                    <>
                                        <hr />
                                        <li className='see-all-btn'>
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark"
                                                style={{ width: 280 }}
                                                onClick={() => navigateToSearchAllWithParams(searchData)}
                                            >
                                                See all
                                            </button>
                                        </li>
                                    </> :
                                    null
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            }

            {/* <PlayerNavbar /> */}
            {/* <CustomBottomNavbar title={'Player'} subdomain={'football/player'} menuItem={true} /> */}

            <div className='container'>
                <div className='d-flex flex-row my-2'>
                    <div className="col-md-2">
                        <img src={userPersonalData.profile_photo} className="img-thumbnail" alt="..." style={{ width: 150, height: 150, objectFit: 'cover' }} />
                    </div>
                    <div className='d-flex flex-column'>
                        <div className="col-auto">
                            <h5>
                                {userPersonalData.first_name} {userPersonalData.last_name}
                            </h5>
                        </div>
                        <div className="col-auto">
                            {permanentAddress.country}
                        </div>
                    </div>
                </div>
                <div className='d-flex row'>
                    <div className='col-md-4'>
                        <div className="card text-bg-light mb-3">
                            <div className="card-header"><b>Personal Information</b></div>
                            <div className="card-body">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='col-md-6'>
                                        <p className='card-text'>Born</p>
                                    </div>
                                    <div className='col-md-6'>
                                        {/* <p className='card-text'>Nov 05, 1988 (34 years)</p> */}
                                        <p className='card-text'>{dobMonth} {dobDay}, {dobYear} ({currentAge} years)</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row justify-content-between mt-2'>
                                    <div className='col-md-6'>
                                        <p className='card-text'>Birth Place</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p className='card-text'>{permanentAddress.city}, {permanentAddress.country}</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row justify-content-between mt-2'>
                                    <div className='col-md-6'>
                                        <p className='card-text'>Height</p>
                                    </div>
                                    <div className='col-md-6'>
                                        {/* <p className='card-text'>5 ft 9 in (175 cm)</p> */}
                                        <p className='card-text'>{heightInFeetInches} ({userPersonalData.height} cm)</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row justify-content-between mt-2'>
                                    <div className='col-md-6'>
                                        <p className='card-text'>Weight</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p className='card-text'>{userPersonalData.weight} kg</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row justify-content-between mt-2'>
                                    <div className='col-md-6'>
                                        <p className='card-text'>Sport</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p className='card-text'>{userPersonalData.sport_type}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card text-bg-light mb-3">
                            <div className="card-header"><b>Personal Achievements</b></div>
                            <div className="card-body">
                                {achievementList}
                            </div>
                        </div>
                        <div className="card text-bg-light mb-3">
                            <div className="card-header"><b>Videos</b></div>
                            <div className="card-body">
                                {sportProfile.map(profile => {

                                    if (profile.profile_type === 'Player' && profile.is_active) {
                                        return (
                                            <div key={profile.id}>
                                                <h5 className='card-text'>Player</h5>
                                                {isPlayerVideoAvailable === '' ?
                                                    <div className='mb-3'>
                                                        {videoInfoList}
                                                    </div> :
                                                    <div className='mb-3'>
                                                        {isPlayerVideoAvailable}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                    if (profile.profile_type === 'Coach' && profile.is_active) {
                                        return (
                                            <div key={profile.id}>
                                                <h5 className='card-text'>Coach</h5>
                                                {isCoachVideoAvailable === '' ?
                                                    <div className='mb-3'>
                                                        {videoInfoListCoach}
                                                    </div> :
                                                    <div className='mb-3'>
                                                        {isCoachVideoAvailable}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                    if (profile.profile_type === 'Agent' && profile.is_active) {
                                        return (
                                            <div key={profile.id}>
                                                <h5 className='card-text'>Agent</h5>
                                                {isAgentVideoAvailable === '' ?
                                                    <div className='mb-3'>
                                                        {videoInfoListAgent}
                                                    </div> :
                                                    <div className='mb-3'>
                                                        {isAgentVideoAvailable}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='profileInfo mx-3'>
                            <h4><b>Profile</b></h4>
                            <p>
                                {profileBio}...
                            </p>
                            {profileBio.length > 100 &&
                                <div className='float-end'>
                                    <button type="button" className="btn btn-link more-text-format" data-bs-toggle="modal" data-bs-target="#profileModal">More...</button>
                                </div>
                            }
                        </div>

                        {sportProfile.map(profile => {
                            if (profile.profile_type === 'Player' && profile.is_active && profile.status === 'Current') {
                                return (
                                    <PlayerProfile
                                        primaryPosition={playerData.primary_position}
                                        secondaryPosition={playerData.secondary_position}
                                        preferredFoot={playerData.preferred_foot}
                                        topSpeed={playerData.top_speed}
                                        isPlayerTnternationalStats={isPlayerTnternationalStats}
                                        internationalHistoryList={internationalHistoryList}
                                        isPlayerDomesticStats={isPlayerDomesticStats}
                                        clubHistoryList={clubHistoryList}
                                        playerSummaryList={playerSummaryList}
                                        isPlayerSummary={isPlayerSummary}
                                        isCoachTnternationalStats={isCoachTnternationalStats}
                                        careerHistoryListInternational={careerHistoryListInternational}
                                        isCoachDomesticStats={isCoachDomesticStats}
                                        careerHistoryListRegional={careerHistoryListRegional}
                                        coachSummaryList={coachSummaryList}
                                        isCoachSummary={isCoachSummary}
                                        agentCareerHistoryList={agentCareerHistoryList}
                                        agentSummaryList={agentSummaryList}
                                        isAgentSummary={isAgentSummary}
                                        countriesIOperate={agentData.country_name}
                                        agentCareerHistoryPlayersAndCoachesUnderMeEndorsementRequest={agentCareerHistoryPlayersAndCoachesUnderMeEndorsementRequest}
                                        sportProfile={sportProfile}
                                    />
                                )
                            } else if (profile.profile_type === 'Coach' && profile.is_active && profile.status === 'Current') {
                                return (
                                    <CoachProfile
                                        primaryPosition={playerData.primary_position}
                                        secondaryPosition={playerData.secondary_position}
                                        preferredFoot={playerData.preferred_foot}
                                        topSpeed={playerData.top_speed}
                                        isPlayerTnternationalStats={isPlayerTnternationalStats}
                                        internationalHistoryList={internationalHistoryList}
                                        isPlayerDomesticStats={isPlayerDomesticStats}
                                        clubHistoryList={clubHistoryList}
                                        playerSummaryList={playerSummaryList}
                                        isPlayerSummary={isPlayerSummary}
                                        isCoachTnternationalStats={isCoachTnternationalStats}
                                        careerHistoryListInternational={careerHistoryListInternational}
                                        isCoachDomesticStats={isCoachDomesticStats}
                                        careerHistoryListRegional={careerHistoryListRegional}
                                        coachSummaryList={coachSummaryList}
                                        isCoachSummary={isCoachSummary}
                                        agentCareerHistoryList={agentCareerHistoryList}
                                        agentSummaryList={agentSummaryList}
                                        isAgentSummary={isAgentSummary}
                                        countriesIOperate={agentData.country_name}
                                        agentCareerHistoryPlayersAndCoachesUnderMeEndorsementRequest={agentCareerHistoryPlayersAndCoachesUnderMeEndorsementRequest}
                                        sportProfile={sportProfile}
                                    />
                                )
                            } else if (profile.profile_type === 'Agent' && profile.is_active && profile.status === 'Current') {
                                return (
                                    <AgentProfile
                                        primaryPosition={playerData.primary_position}
                                        secondaryPosition={playerData.secondary_position}
                                        preferredFoot={playerData.preferred_foot}
                                        topSpeed={playerData.top_speed}
                                        isPlayerTnternationalStats={isPlayerTnternationalStats}
                                        internationalHistoryList={internationalHistoryList}
                                        isPlayerDomesticStats={isPlayerDomesticStats}
                                        clubHistoryList={clubHistoryList}
                                        playerSummaryList={playerSummaryList}
                                        isPlayerSummary={isPlayerSummary}
                                        isCoachTnternationalStats={isCoachTnternationalStats}
                                        careerHistoryListInternational={careerHistoryListInternational}
                                        isCoachDomesticStats={isCoachDomesticStats}
                                        careerHistoryListRegional={careerHistoryListRegional}
                                        coachSummaryList={coachSummaryList}
                                        isCoachSummary={isCoachSummary}
                                        agentCareerHistoryList={agentCareerHistoryList}
                                        agentSummaryList={agentSummaryList}
                                        isAgentSummary={isAgentSummary}
                                        countriesIOperate={agentData.country_name}
                                        agentCareerHistoryPlayersAndCoachesUnderMeEndorsementRequest={agentCareerHistoryPlayersAndCoachesUnderMeEndorsementRequest}
                                        sportProfile={sportProfile}
                                    />
                                )
                            }
                        })}

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

